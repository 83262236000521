import { i18n } from "./i18n.config";
import { Link, useParams } from "react-router-dom";
// import styles from "@/styles/header.module.scss";

export default function LocaleSwitcher({ setHandleUpdate }) {
  // current restorant from url path
  const restorant = window.location.pathname.split("/")[1];
  console.log(restorant);
  return (
    <ul style={{ listStyle: "none", display: "flex", gap: 10 }}>
      {i18n.locales.map((locale) => {
        return (
          <li key={locale}>
            <Link
              onClick={() => setHandleUpdate((prev) => prev + 1)}
              to={`/${restorant}/${locale}`}
              className={"locale-link"}
            >
              {/* <a href={`/`} className={"locale-link"}> */}
              {locale}
              {/* </a> */}
            </Link>
          </li>
        );
      })}
    </ul>
  );
}
