import React from "react";
// import text404 from "../public/text-404.svg";
// import styles from "@/styles/custom404.module.scss";
import './custom404.css';
const Custom404 = () => {
  return (
   <div style={{textAlign: "center", width: "100%", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
      <h1 style={{fontSize: "3rem"}}>404 - Page Not Found</h1>
      {/* <img src={text404} alt="404" /> */}
    </div>
  );
};

export default Custom404;
