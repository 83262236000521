
const MenuImage = ({ img, title }) => {


  return (
    <>
      <div
       
        style={{
        }}
        className={"card-image--wrap"}
      >
        <img
          priority
          fill
          className={"menu-image"}
          sizes="(max-width: 640px) 100%, (min-width: 640px) 50%,(min-width: 960px) 33%,(min-width: 1200px) 25%"
          src={img}
          alt={title}
        />
        <h3>{title}</h3>
      </div>
    </>
  );
};

export default MenuImage;
