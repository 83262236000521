import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  BrowserRouter,
  Routes,
  useParams,
} from "react-router-dom";
import MenuList from "./MenuList";
import Custom404 from "./Custom404";
import Header from "./Header";
import axios from "axios";
import { useEffect } from "react";

const config = {
  "80lvl": {
    en: 7,
    ua: 8,
  },
  neoport: {
    en: 5,
    ua: 6,
  },
};

function App() {
  const restaurantId =
    window.location.pathname.split("/")[1] === "80lvl" ? 7 : 8;
  const lang = window.location.pathname.split("/")[2] === "en" ? "ua" : "en";

  const [categories, setCategories] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [handleUpdate, setHandleUpdate] = React.useState(0);

  useEffect(() => {
    if (
      !window.location.pathname.includes("neoport") &&
      !window.location.pathname.includes("80lvl")
    ) {
      return;
    }
    const restId = config[window.location.pathname.split("/")[1]][lang];
    const url = `${process.env.REACT_APP_BASE_API_PATH}api/restaurants/${restId}?populate[0]=DishCategories.Dishes&populate[1]=DishCategories.CoverImage`;

    axios
      .get(url)
      .then((response) => {
        setCategories(response.data.data.attributes.DishCategories);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [handleUpdate]);

  const hasHeader =
    window.location.pathname.includes("neoport") ||
    window.location.pathname.includes("80lvl");

  return (
    <>
      <Router>
        {hasHeader && <Header setHandleUpdate={setHandleUpdate} />}
        <Routes>
          <Route
            path="/neoport"
            element={
              <MenuList isLoading={loading} DishCategories={categories} />
            }
          />
          <Route
            path="/neoport/en"
            element={
              <MenuList isLoading={loading} DishCategories={categories} />
            }
          />
          <Route
            path="/neoport/ua"
            element={
              <MenuList isLoading={loading} DishCategories={categories} />
            }
          />
          <Route
            path="/80lvl"
            element={
              <MenuList isLoading={loading} DishCategories={categories} />
            }
          />
          <Route
            path="/80lvl/en"
            element={
              <MenuList isLoading={loading} DishCategories={categories} />
            }
          />
          <Route
            path="/80lvl/ua"
            element={
              <MenuList isLoading={loading} DishCategories={categories} />
            }
          />
          <Route path="*" element={<Custom404 />} />
        </Routes>
      </Router>
      {/* <MenuList DishCategories={categories} /> */}
    </>
  );
  // <Custom404 />
}

export default App;
/* <h1> Hello </h1>
<Router>
  {
  <Route exact path="/" component={HomePage} />

 
  <Route path="/neoport/en" component={MenuList} />


  <Route path="/some/specific/path" component={SpecificPage} />

 
 <Route path="*" component={Custom404} />
// </Router> */
