import Header from "./Header";
import MenuItem from "./MenuItem";

// const MenuList = async ({
//   DishCategories,
// }) => {
function MenuList({ DishCategories, isLoading }) {
  console.log(DishCategories, DishCategories?.data?.length);

  if (isLoading) {
    return <div class="loader"></div>;
  }

  return (
    <div className={"cards-list-wrap"}>
      <ul className={"cards-list"}>
        {!DishCategories?.data?.length && (
          <div className={"no-dishes"}>
            <p>No dishes available</p>
          </div>
        )}
        {DishCategories?.data?.length &&
          DishCategories.data.map((menuItem, index) => (
            <MenuItem key={menuItem.id} menuItem={menuItem.attributes} />
          ))}
      </ul>
    </div>
  );
}

export default MenuList;
// {
//   DishCategories?.data?.length === 0 && (
//     <div className={styles["no-dishes"]}>
//       <p>No dishes available</p>
//     </div>
//   )
// }
