import LocaleSwitcher from "./LocaleSwitcher";
// import styles from "@/styles/header.module.scss";
const Header = ({setHandleUpdate}) => {
  return (
    <header className={"header"}>
      <LocaleSwitcher setHandleUpdate={setHandleUpdate} />
    </header>
  );
};

export default Header;
