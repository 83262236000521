// import styles from "./styles/menu.module.scss";
import MenuImage from "./MenuImage";

const MenuItem = ({ menuItem }) => {
  const { DisplayName, CoverImage, Dishes } = menuItem;

  return (
    <li className={"card"}>
      <div className={"card-image-container"}>
        <MenuImage
          img={
            CoverImage?.data
              ? `${
                  process.env.REACT_APP_BASE_API_PATH +
                  CoverImage?.data?.attributes?.url
                }`
              : "/empty-bg.png"
          }
          title={DisplayName}
        />
      </div>
      {Dishes.data.length &&
        Dishes.data.map((position, index) => {
          const { DishName, Description, Price, Volume } = position.attributes;
          return (
            <div key={index} className={"card-info"}>
              <div className={"card-info--left"}>
                <h4>{DishName}</h4>
                {Description && <p>{Description}</p>}
              </div>

              <div className={"card-info--right"}>
                <div className={"card-nums-wrap"}>
                  <strong>
                    <span className={"currency"}>₴</span>
                    {Price}
                  </strong>
                  <span>{Volume}</span>
                </div>
              </div>
            </div>
          );
        })}
    </li>
  );
};

export default MenuItem;
